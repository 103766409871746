// Here you can add other styles
.form-control:focus, button:active, button:focus, select:focus {
    box-shadow: none;
}
.centered-view-master {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--cui-light, #ebedef) !important;
}
.max-w-100 {
    max-width: 100%;
    width: 100%;
}
.max-h-100 {
    max-height: 100%;
    height: 100%;
}
.flex-1 {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.danger {
    color: red;
}
.w-15 {
    width: 15px;
}
.inline-flex {
    display: inline-flex;
}

.ml-1 {
    margin-left: 0.1rem;
}
.ml-2 {
    margin-left: 0.2rem;
}
.ml-3 {
    margin-left: 0.3rem;
}
.ml-4 {
    margin-left: 0.4rem;
}
.ml-5 {
    margin-left: 0.5rem;
}
.ml-6 {
    margin-left: 0.6rem;
}

.mr-1 {
    margin-right: 0.1rem;
}
.mr-2 {
    margin-right: 0.2rem;
}
.mr-3 {
    margin-right: 0.3rem;
}
.mr-4 {
    margin-right: 0.4rem;
}
.mr-5 {
    margin-right: 0.5rem;
}
.mr-6 {
    margin-right: 0.6rem;
}

.btn-danger, .btn-secondary {
    color: #fff;
}
.hand {
    cursor: pointer;
}
.map {
    width: 100%;
    height: 500px;
}
.map-container {
    max-width: calc(100% - 30px);
    max-height: 450px;
}
.pull-right {
    text-align: right;
}

.updated-hour {
    align-items: center;
    justify-content: end;
    padding-right: 30px;
}
.justify-end {
    justify-content: flex-end!important;
}

.title--journey {
    margin: 0px;
    padding: 10px;
    font-size: 18px;
}

.btn, .btn:hover, .btn:focus {
    color: #fff!important;
    --cui-btn-hover-color: #fff!important;
}

.filter--area {
    border: 1px solid #e2e2e2;
    margin: 15px 10px 10px;
    padding: 5px 10px;
    background-color: #f9f9f9;
    box-shadow: 0px 0px 5px 5px #e7e7e7;
}

.title--filter--area {
    font-size: 18px;
}

.tab-content > .active {
    border: 1px solid #ccc;
    border-top: 0;
}

.welcome {
    padding: 20px;
}

.welcome-login {
    cursor: pointer;
    position: absolute;
    margin-top: -40px;
    right: 20px;
}

.welcome h1 {
    text-align: center;
}

.welcome .li-title {
    font-weight: bold;
    list-style: none;
}

.rmdp-container {
    width: inherit !important;
}

.rmdp-input {
    width: inherit !important;
    padding: 0.375rem 0.75rem !important;
    height: auto!important;
}

.tab-content > .tab-pane {
    padding: 10px !important;
}

.css-1insrsq-control {
    background-color: hsl(218deg 11% 86%) !important;
    border-color: hsl(217deg 11% 73%) !important;
}

.css-lr9fot-singleValue {
    color: hsl(215deg 21% 27%) !important;
}

.vertical-timeline::before {
    height: 0px !important;
}

.vertical-timeline>div:first-child::before {
    content: '';
    position: absolute;
    top: 30px;
    left: 18px;
    height: calc(100% + 4rem);
    width: 4px;
    background: var(--line-color);
}

.vertical-timeline>div::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: calc(100% + 4rem);
    width: 4px;
    background: var(--line-color);
}

.vertical-timeline>div:last-child::before {
    content: '';
    position: absolute;
    top: 30px;
    left: 18px;
    height: 0px !important;
    width: 4px;
    background: var(--line-color);
}
.vertical-timeline-element-icon {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.button-financial {
    flex: 1;
    font-size: small;
    padding: 5px;
    background-color: transparent;
    border: 1px solid #ccc;
    border-left: 0px;
    border-right: 0px;
    color: #333;
    cursor: pointer;
    text-align: center;
}
.button-financial.active {
    border-color: var(--cui-primary);
    background-color: var(--cui-primary);
    color: #fff;
}
.button-financial-group{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.button-financial-group:first-child {
    border-left: 1px solid #ccc;
}
.button-financial-group:last-child {
    border-right: 1px solid #ccc;
}

header.sc-dJjYzT {
    padding: 0px;
    margin-bottom: -8px;
}
.ep-arrow.rmdp-ep-arrow.rmdp-ep-shadow.form-control.form-control-sm {
    visibility: collapse !important;
}

.badge-custom {
    border: 1px solid #ccc;
    width: max-content;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #e33714;
    border-color: #e33714;
    color: #fff;
    font-weight: 500;
    opacity: 15;
    margin: 2px;
}

@keyframes blinker {
  from {opacity: 1.0;}
  to {opacity: 0.0;}
}
.blink{
	text-decoration: blink;
	-webkit-animation-name: blinker;
	-webkit-animation-duration: 0.6s;
	-webkit-animation-iteration-count:infinite;
	-webkit-animation-timing-function:ease-in-out;
	-webkit-animation-direction: alternate;
	animation-name: blinker;
	animation-duration: 0.6s;
	animation-iteration-count:infinite;
	animation-timing-function:ease-in-out;
	animation-direction: alternate;
}

.timeline-hidden .vertical-timeline-element-date {
    display: none!important;
}

.timeline-hidden .vertical-timeline-element-content {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}

.card-custom .card-header, .card-custom .rdt_Pagination, .card-custom .rdt_Pagination>div>button, .card-custom header{
    background-color: rgb(9, 51, 83) !important;
    color: rgba(255, 255, 255, 0.87) !important;
    fill: rgba(255, 255, 255, 0.87) !important;
}
.card-custom .rdt_Pagination>div>button:hover {
    opacity: 0.25;
}
.card-custom .rdt_Pagination>div>button[disabled] {
    fill: #ffffff24 !important;
    :hover {
        opacity: 1 !important;
    }
}

.card-custom .card-header {
    border-color: rgba(255, 255, 255, 0.87);
}
.card-custom .card-body {
    padding: 0;
}

.timeline-custom {
    height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    // scrollbar-width: auto;
    scrollbar-color: #1569a9 #ffffff;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #1569a9 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 2px!important;
  height: 2px!important;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #1569a9;
  border-radius: 5px;
  border: none;
}
*::-webkit-scrollbar-button {
  display: none!important;
  visibility: hidden;
}


.sidebar-nav {
scrollbar-width: thin;
scrollbar-color: rgba(44, 56, 74, 0.95) #3c4b64;
}
.sidebar-nav::-webkit-scrollbar {
  width: 5px!important;
}

.sidebar-nav::-webkit-scrollbar-track {
  background: #3c4b64!important;
}

.sidebar-nav::-webkit-scrollbar-thumb {
  background-color: rgba(44, 56, 74, 0.95)!important;
  border-radius: 0px!important;
  border: none!important;
}
.kBLiHP {
    padding: 5px 0px !important;
}
.nav-tabs .nav-item {
    cursor: pointer;
}

.form-group-custom {
    display: inline-flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
}
.form-group-custom div {
    outline: 0px;
    border: 0px;
}
.form-group-custom .form-check {
    padding: 5px;
}
.form-group-custom .form-check-label {
    width: 200px;
}

.form-group-custom .input-check-form {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    border-radius: 0px;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header:not(.tox-editor-dock-transition) {
    z-index: 0!important;
    border-bottom: 2px solid #ddd;
}

.chat-list-area {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
}

.chat-list-area .chat-item-right {
    align-self: self-end;
    background-color: var(--cui-primary);
    border-color: var(--cui-primary);
    color: #fff;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.chat-list-area .chat-item-left {
    display: grid;
    align-self: self-start;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.chat-item-area {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 30px;
    margin: 5px;
    width: 80%;
}


.chat-item-name {
    font-weight: bold;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
}

.chat-item-message {
    padding: 5px 0px 20px;
}

.chat-item-footer {
    text-align: end;
    border-top: 1px solid #ccc;
}

.chat-item-date {
    font-size: 10px!important;

    svg {
        margin-top: 5px;
        margin-left: 5px;
    }
}
.chat-item-footer svg {
    margin-top: 5px!important;
    margin-left: 5px!important;
}
